@import 'utils';

.speech_bubble {
  --color: var(--color-neutral-300);
  --arrow-size: 25px;
  position: relative;
  background: var(--color);
  border-radius: 36px;
  padding: spacing(10);
  display: block;
  margin-bottom: calc(var(--arrow-size) + spacing(4));

  &:after {
    content: '';
    position: absolute;
    bottom: calc(var(--arrow-size) * -1);
    right: 25%;
    border-width: var(--arrow-size) var(--arrow-size) 0;
    border-style: solid;
    border-color: var(--color) transparent;
  }
}
