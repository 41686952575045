@import 'utils';

.hero {
  --bg-color: var(--color-foreground-hex);

  background: var(--landing-hero-gradient-color);
  background-image: linear-gradient(100deg, var(--landing-hero-gradient-color), var(--bg-color), var(--bg-color));

  > .header {
    color: var(--color-background-hex);
    @include up(desktop) {
      .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 65px;
        transition: font-size 0.3s ease;
      }
    }
  }

  > .hero_image {
    display: none;
    @include up(desktop) {
      display: block;
      position: relative;
      background-repeat: no-repeat;
      background-clip: padding-box;
      background-size: cover;
      background-position: 100px;
      .thumbs {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.thumbs_wrapper {
  --dot-color: var(--color-brand-tangerine);
  height: 100%;
  position: relative;
  // "Dots" line over the images
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 155px;
    height: 70%;
    border-bottom-left-radius: 24px;
    background-image: radial-gradient(circle, var(--dot-color) 3px, transparent 3px),
      radial-gradient(circle, var(--dot-color) 3px, transparent 3px);
    background-position: left 8px bottom, left top;
    background-repeat: repeat-x, repeat-y;
    background-size: 26px 6px, 6px 26px;
  }
}

.thumbs {
  display: grid;
  grid-template-areas:
    '. icon'
    'photo .';

  .icon {
    background-color: var(--color-brand-tangerine);
    width: 114px;
    height: 114px;
    padding: spacing(6);
    display: flex;
    align-items: center;
    grid-area: icon;
  }

  .photo {
    grid-area: photo;
    background-color: var(--landing-hero-gradient-color);
    width: 195px;
    height: 187px;
  }
}

.highlight {
  color: var(--color-brand-tangerine);
}

.subtitle {
  line-height: 32px;
}

.title {
  margin-bottom: spacing(6);
}

.prod_bg_img {
  background: url('/peer-community/nextjsv2/img/landing_page_hero_bg.webp');
}

.non_prod_bg_img {
  background: url('/peer-community/img/landing_page_hero_bg.webp');
}
