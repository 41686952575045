@import 'utils';

.modal_signup {
  .image {
    display: block;
    margin: 0 auto 30px;
  }

  .containerList {
    margin-bottom: 15px;
    padding: 25px;
  }
}
