@import 'utils';

.activity {
  @include flex-row;
  gap: spacing(3);
  color: var(--color-product-text);
  svg {
    display: block;
  }
  .activity_profile {
    margin-bottom: 3px;
  }
}
