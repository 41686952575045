@import 'utils';

.section {
  align-items: center;
  h2,
  p {
    margin-bottom: spacing(6);
    color: var(--color-product-text);
  }

  h2 {
    line-height: 44px;
    @include up(tablet) {
      line-height: 54px;
    }
  }
}
