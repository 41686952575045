@import 'utils';

.progress_bar {
  display: flex;
  color: var(--color-product-text);
  padding: spacing(4);
  background-color: var(--color-polar);
  margin-bottom: spacing(4);
  border-radius: 4px;
  background-image: linear-gradient(var(--color-brand-surf), var(--color-brand-surf));
  background-size: var(--progress) 100%;
  background-repeat: no-repeat;
}

.bold {
  font-weight: 600;
}
