@import 'utils';

.form {
  svg {
    left: 20px;
    position: relative;
    top: -36px;
  }
}

.input {
  display: flex;
  align-items: center;
  background: var(--neutrals-01-white);
  border: 1px solid var(--color-neutral-400);
  border-radius: 8px;
  color: var(--color-product-text);
  font-size: 16px;
  padding: 10px spacing(6) 10px spacing(15);
  height: 48px;
  width: 100%;
}
