@import 'utils';

.community_feed {
  background-color: var(--color-background-hex);
  padding: spacing(6);
  min-height: 128px;
  overflow: hidden;
  position: relative;
  .community_feed_title {
    text-transform: uppercase;
    font-weight: 500;
    color: var(--color-neutral-500);
    margin-bottom: spacing(6);
  }
}

@keyframes append {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.activity {
  animation: append 0.3s ease-out;
}
