@import 'utils';

.fake_post {
  background-color: var(--color-background-hex);
  padding: spacing(8);
  border-radius: 8px;
  box-shadow: var(--box-shadow-01-normal);
  color: var(--color-product-text);

  .tabs {
    border-bottom: 2px solid var(--color-neutral-400);
  }

  .tab {
    display: inline-block;
    padding: spacing(3) spacing(5);
    font-weight: 500;
    position: relative;
    top: 2px;
    &.tab_selected {
      color: var(--color-utility-interaction-700);
      border-bottom: 2px solid var(--color-utility-interaction-700);
    }
  }

  .content {
    border-radius: 4px;
    border: 1px solid var(--color-neutral-300);
    padding: spacing(4);
  }

  .tag {
    font-weight: 500;
    display: inline-block;
    color: var(--color-utility-interaction-700);
    background-color: var(--color-polar);
    padding: spacing(2) spacing(4);
    border-radius: 24px;
    margin: 0 spacing(4) spacing(2) 0;
    .tag_close {
      margin-left: spacing(2);
    }
  }

  .skeleton_loader {
    > div {
      background-color: var(--color-neutral-400);
      height: 16px;
      margin-bottom: spacing(4);
      border-radius: 6px;
    }
    :nth-child(2) {
      width: 70%;
    }
    :nth-child(3) {
      width: 80%;
    }
    :nth-child(4) {
      width: 40%;
    }
    :last-child {
      margin-bottom: spacing(8);
    }
  }

  .checkbox {
    vertical-align: middle;
    margin-right: spacing(4);
    &::before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 12 10" xml:space="preserve"><path style="fill: white" d="m9.93 2.339.108-.128-.128-.107-.511-.428-.128-.107-.107.127-4.07 4.85-2.362-1.98-.128-.107-.107.127-.428.51-.107.128.127.107 3 2.518.128.108.107-.128 4.607-5.49Z"/></svg>');
      background-color: var(--color-utility-interaction-600);
    }
  }

  .tags,
  .post_anonymously {
    margin: spacing(5) 0;
  }
}
