@import 'utils';

.main {
  --container-width: 1440px;
  --num-columns: 1;
  --vertical-spacing: #{spacing(10)};
  --spacing: #{spacing(12)};
  --column-gap: #{spacing(5)};

  @include up(tablet) {
    --spacing: #{spacing(25)};
  }

  @include up(desktop) {
    --num-columns: 12;
    --vertical-spacing: #{spacing(20)};
  }

  display: grid;
  grid-template-columns:
    [spacing-left] 1fr
    repeat(
      var(--num-columns),
      [content-start] calc(min((100% - var(--spacing)), var(--container-width)) / var(--num-columns))
    )
    [spacing-right] 1fr;

  > section,
  footer {
    grid-column: 1 / -1;
    > * {
      grid-column: content-start / spacing-right;
    }
  }

  .hero,
  .questions,
  .topics,
  .polls,
  .ambassadors {
    display: grid;
    grid-template-columns: subgrid;
    @include up(desktop) {
      > :first-child {
        grid-column: 2 / 8;
      }
      > :last-child {
        grid-column: 9 / -2;
      }
    }
  }

  // discussions, faq and footer, are reusable and have their own internal layout,
  // so we just put them in the content column instead of using the grid for internal layout
  .discussions,
  .faq,
  .footer {
    grid-column: content-start / spacing-right;
  }

  .questions,
  .topics,
  .polls,
  .ambassadors {
    & > :first-child {
      margin-bottom: var(--vertical-spacing);
      @include up(desktop) {
        margin-bottom: 0;
      }
    }
  }

  > :not(.hero, .join_banner),
  > .hero > :first-child {
    padding-block: var(--vertical-spacing);
  }

  .join_banner {
    grid-column: 2 / -1; // join image banner bleeds right
  }

  .hero > :last-child {
    grid-column-end: -1; // hero image bleeds right
  }

  input[placeholder] {
    border: none;
    background-color: var(--color-neutral-200);
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  // Reset some hardcoded layout styles in the FAQ and footer components

  .faq,
  .footer {
    padding-inline: 0;
  }

  .footer {
    max-width: unset;
    margin: unset;
  }
}

.questions {
  background-color: var(--color-neutral-200);
}

.ambassadors {
  background-color: var(--color-polar);
}
